import {
	$,
	$$
} from "@/modules/_utils/dom/select";
import Swiper from "swiper";

export default () => {
	const swipers = $$("[data-swiper-id]");

	Array.prototype.forEach.call(swipers, swiper => {
		const id = swiper.getAttribute("data-swiper-id");
		const row = swiper.getAttribute("data-swiper-row");

		if (swiper.getAttribute("data-swiper-row") === "4") {
			new Swiper(swiper, {
				slidesPerView: 4,
				slidesPerGroup: 3,
				pagination: {
					el: ".swiper-pagination",
					type: "bullets",
					clickable: true
				},

				navigation: {
					nextEl: ".main-slider__arrow--right",
					prevEl: ".main-slider__arrow--left"
				},

				breakpoints: {
					575: {
						slidesPerView: 1,
						slidesPerGroup: 1,
						spaceBetween: 10
					},
					767: {
						slidesPerView: 1,
						slidesPerGroup: 1,
						spaceBetween: 10
					},
					991: {
						slidesPerView: 2,
						slidesPerGroup: 1,
						spaceBetween: 20
					},
					1200: {
						slidesPerView: 3,
						slidesPerGroup: 3,
						spaceBetween: 20
					}
				}

			});
		} else if (swiper.getAttribute("data-swiper-row") === "3") {
			if (swiper.getAttribute("data-swiper-id") === "newsSlider") {
				new Swiper(swiper, {
					slidesPerView: 3,
					slidesPerGroup: 3,

					pagination: {
						el: ".swiper-pagination",
						type: "bullets",
						clickable: true
					},
					navigation: {
						nextEl: ".new-slider__arrow--right.news",
						prevEl: ".new-slider__arrow--left.news"
					},
					breakpoints: {
						575: {
							slidesPerView: 1,
							slidesPerGroup: 1,
							spaceBetween: 10
						},
						767: {
							slidesPerView: 1,
							slidesPerGroup: 1,
							spaceBetween: 10
						},
						991: {
							slidesPerView: 2,
							slidesPerGroup: 1,
							spaceBetween: 20
						},
						1200: {
							slidesPerView: 3,
							slidesPerGroup: 3,
							spaceBetween: 20
						}
					}
				});
			} else {
				new Swiper(swiper, {
					slidesPerView: 3,
					slidesPerGroup: 3,
					spaceBetween: 20,

					pagination: {
						el: ".swiper-pagination",
						type: "bullets",
						clickable: true
					},
					navigation: {
						nextEl: ".new-slider__arrow--right.sales",
						prevEl: ".new-slider__arrow--left.sales"
					},
					breakpoints: {
						575: {
							slidesPerView: 1,
							slidesPerGroup: 1,
							spaceBetween: 10
						},
						767: {
							slidesPerView: 1,
							slidesPerGroup: 1,
							spaceBetween: 10
						},
						991: {
							slidesPerView: 2,
							slidesPerGroup: 1,
							spaceBetween: 20
						},
						1200: {
							slidesPerView: 3,
							slidesPerGroup: 3,
							spaceBetween: 20
						}
					}
				});
			}
		} else if (swiper.getAttribute("data-swiper-id") === "mainpic") {

			new Swiper(swiper, {
				thumbs: {
					swiper: {
						el: '.product_gallery__previews',
						slidesPerView: 3,
						navigation: {
							nextEl: ".product_gallery__arrow--right",
							prevEl: ".product_gallery__arrow--left"
						}
					}
				},
				pagination: {
					el: ".main_pic__pagination",
					type: "bullets",
					clickable: true
				},
			});

		} else if (swiper.getAttribute("data-swiper-id") === "previews") {
			return false;
		} else {
			new Swiper(swiper, {
				pagination: {
					el: ".swiper-pagination",
					type: "bullets",
					clickable: true
				},
				navigation: {
					nextEl: "#sliderRight",
					prevEl: "#sliderLeft"
				}
			});
		}
	});
};
