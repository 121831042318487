import $ from "jquery";
import "ion-rangeslider";

// export default () => $(".rangeSlider").ionRangeSlider();

export default (selector, options) => {
  const defaultOptions = {
    type: "single",

    min: 10,
    max: 100,
    from: null,
    to: null,
    step: 1,

    min_interval: 0,
    max_interval: 0,
    drag_interval: false,

    values: [],
    p_values: [],

    from_fixed: false,
    from_min: null,
    from_max: null,
    from_shadow: false,

    to_fixed: false,
    to_min: null,
    to_max: null,
    to_shadow: false,

    prettify_enabled: true,
    prettify_separator: " ",
    prettify: null,

    force_edges: false,

    keyboard: true,

    grid: false,
    grid_margin: true,
    grid_num: 4,
    grid_snap: false,

    hide_min_max: false,
    hide_from_to: false,

    prefix: "",
    postfix: "",
    max_postfix: "",
    decorate_both: true,
    values_separator: " — ",

    input_values_separator: ";",

    disable: false,
    block: false,

    extra_classes: "",

    scope: null,
    onStart: null,
    onChange: null,
    onFinish: null,
    onUpdate: null
  };
  const mergedOptions = Object.assign({}, defaultOptions, options);

  return $(selector).ionRangeSlider(mergedOptions);
};
