import init from "@/modules/_utils/plugin-init";
import simulate from "@/modules/_utils/event/simulate";
import { $ } from "@/modules/_utils/dom/select";

class Header {
  constructor(element, options) {
    this.element = element;
    this.name = "header";

    this._defaults = {
      scrolledHeaderClassName: "headerScrolled",
      unScrolledHeaderClassName: "headerUnScrolled",
      offsetTop: 50
    };

    this.options = {
      ...this._defaults,
      ...options
    };

    this.init();
  }

  init() {
    this.bindEvents();
    // this.checkScrollTop();
  }

  bindEvents() {
    window.addEventListener("scroll", event => {
      const scroll =
        window.scrollY ||
        window.scrollTop ||
        document.getElementsByTagName("html")[0].scrollTop;
      const offset = this.options.offsetTop;
      const scrollClass = this.options.scrolledHeaderClassName;
      const unScrollClass = this.options.unScrolledHeaderClassName;
      if (scroll > offset) {
		document.getElementsByTagName("body")[0].classList.add('menuScrolled');
        this.element.classList.remove(unScrollClass);
        this.element.classList.add(scrollClass);
      } else {
		document.getElementsByTagName("body")[0].classList.remove('menuScrolled');
        this.element.classList.remove(scrollClass);
        this.element.classList.add(unScrollClass);
      }
    });
  }

  //  checkScrollTop() {
  //    let scroll = window.scrollTop;
  //    let offset = this.options.offsetTop;
  //    let scrollClass = this.options.scrolledHeaderClassName;
  //    let unScrollClass = this.options.unScrolledHeaderClassName;
  //    if (scroll > offset) {
  //      this.element.classList.add(scrollClass);
  //    } else {
  //      this.element.classList.remove(unScrollClass);
  //    }
  //    console.log("ok");
  // }
}

export default init(Header);
