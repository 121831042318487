import init from "../_utils/plugin-init";
import { $ } from "../_utils/dom/select";
import { $$ } from "../_utils/dom/select";
import getSiblings from "../_utils/dom/getSiblings";
import attr from "../_utils/dom/attr";
import closest from "../_utils/polyfill/closest";

class Catview {
  constructor(element, options) {
    this.element = element;
    this.name = "cat-filter__tgls";

    this._defaults = {
      togglerClass: ".cat-filter__toggle",
      activeToggleClass: "cat-filter__toggle--active",
      container: ".cat-list",
      viewClassList: "catviewList",
      viewClassGrid: "catviewGrid"
    };

    this.options = {
      ...this._defaults,
      ...options
    };

    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const plugin = this;
    const elClick = $$(plugin.options.togglerClass);
    const elActive = $$(plugin.options.activeToggleClass);
    let siblings = getSiblings($(plugin.options.togglerClass));
    const wares = $(plugin.options.container);

    Array.prototype.forEach.call(elClick, function(button) {
      button.addEventListener("mouseup", function(event) {
        let dataView = this.getAttribute("data-view");
        console.log(dataView);
        plugin.removeClasses(siblings, plugin.options.activeToggleClass);
        if (!this.classList.contains(plugin.options.activeToggleClass)) {
          let sibl = getSiblings(this);
          button.classList.add(plugin.options.activeToggleClass);
          sibl[0].classList.remove(plugin.options.activeToggleClass);
          // console.log(wares);
          if (dataView === "view-list") {
            wares.classList.remove(plugin.options.viewClassGrid);
            wares.classList.add(plugin.options.viewClassList);
          }
          if (dataView === "view-grid") {
            wares.classList.remove(plugin.options.viewClassList);
            wares.classList.add(plugin.options.viewClassGrid);
          }
        } else {
          return false;
        }
      });
    });
  }
  hasClass(elem, className) {
    return (" " + elem.className + " ").indexOf(" " + className + " ") > -1;
  }
  removeClasses(elems, className) {
    Array.prototype.forEach.call(elems, function(elem) {
      elem.classList.remove(className);
    });
  }
  // addClass(elem, className) {
  //   if (!elem.classList.contains(className)) {
  //     return elem.classList.add(className);
  //   }
  // }
}

export default init(Catview);
